import React from 'react';
import './assets/style/style.css';
import { slide as Menu } from 'react-burger-menu';
import { Helmet } from "react-helmet";
import { functions } from './components/firebase/firebase';
import AnimatedNumbers from "react-animated-numbers";
import facebook from './assets/images/facebook.svg';
import instagram from './assets/images/instagram.svg';
import logo from './assets/images/logo.svg';
import logo3 from './assets/images/logo3.svg';
import appstore from './assets/images/appstore.svg';
import googleplay from './assets/images/googleplay.svg';
import googleplay_white from './assets/images/googleplay_white.svg';
import mail from './assets/images/mail.svg';
import phone from './assets/images/phone.svg';
import barsale from './assets/images/barsale.svg';
import waarom1 from './assets/images/waarom1.jpg';
import waarom2 from './assets/images/waarom2.jpg';
import waarom3 from './assets/images/waarom3.jpg';
import extras1 from './assets/images/extras1.svg';
import extras2 from './assets/images/extras2.svg';
import extras3 from './assets/images/extras3.svg';
import extras4 from './assets/images/extras4.svg';
import extras5 from './assets/images/extras5.svg';
import extras6 from './assets/images/extras6.svg';
import beanBG2 from './assets/images/beanBG2.svg';
import ballBG2 from './assets/images/ballBG2.svg';
import ballBG from './assets/images/ballBG.svg';
import quoteBG from './assets/images/quoteBG.jpg';
import dbImg from './assets/images/dbImg.png';
import kantoor from './assets/images/kantoor.png';
import festivalbar from './assets/images/festivalbar.png';
import headerMockups from './assets/images/headerMockups.png';
import joep from './assets/images/joep.jpg';
import knot from './assets/images/knot.jpg';
import rob from './assets/images/rob.jpg';
import pieter from './assets/images/pieter.jpg';
import eventix from './assets/images/eventix.svg';
import happyfeelings from './assets/images/happyfeelings.svg';
import mollie from './assets/images/mollie.svg';
import lockercompany from './assets/images/lockercompany.svg';
import eventsafe from './assets/images/eventsafe.svg';
import ytp from './assets/images/ytp.svg';
import pay from './assets/images/pay.svg';
import visImg from './assets/images/appreviews.png';
import festivalcadeau from './assets/images/festivalcadeau.svg';
import paylogic from './assets/images/paylogic.svg';
import asopos from './assets/images/asopos.svg';
import breepark from './assets/images/breepark.svg';
import tibbaa from './assets/images/tibbaa.svg';
import gotickets from './assets/images/gotickets.svg';
import keynius from './assets/images/keynius.svg';
import colorado from './assets/images/colorado.svg';
import papichulo from './assets/images/papichulo.svg';


import ReactGA from 'react-ga';

class Ppsite extends React.Component {
	
	constructor(props) {
		super(props)
		this.waaromRef = React.createRef()
		this.recensieRef = React.createRef()
		this.overRef = React.createRef()
		this.contactRef = React.createRef()
	}	

	state = {
		currRecensie: 1, 
		currFaqItem: 0,
		mailsend: false, 
		mail: '', 
		name: '', 
		tel: '',
		msg: '', 
		emptyMail: false, 
		emptyMsg: false, 
		emptyName: false,
		emptyTel: false
	}

	componentDidMount(){ReactGA.initialize("UA-229590028-1"); this.leadinfo()}

	sendMail(){
		if(this.state.mail === ''){this.setState({emptyMail: true})}
		else if(this.state.name === ''){this.setState({emptyName: true})}
		else if(this.state.tel === ''){this.setState({emptyTel: true})}
		else if(this.state.msg === ''){this.setState({emptyMsg: true})}
		else{
			this.setState({mailsend: true, mail: '', name: '', msg: '', tel: '', emptyMail: false, emptyMsg: false, emptyName: false});
			var sendMail = functions.httpsCallable('sendMail');
			sendMail({mailType: 'contact', reciever: 'info@partypay.nl', afzenderMail: this.state.mail, afzenderNaam: this.state.name, afzenderTel: this.state.tel, afzenderBericht: this.state.msg});
			this.addwordsConversion('contactform');
		}
	}

	leadinfo(){
		(function(l,e,a,d,i,n,f,o){if(!l[i]){l.GlobalLeadinfoNamespace=l.GlobalLeadinfoNamespace||[];
		l.GlobalLeadinfoNamespace.push(i);l[i]=function(){(l[i].q=l[i].q||[]).push(arguments)};l[i].t=l[i].t||n;
		l[i].q=l[i].q||[];o=e.createElement(a);f=e.getElementsByTagName(a)[0];o.async=1;o.src=d;f.parentNode.insertBefore(o,f);}
		}(window,document,"script","https://cdn.leadinfo.net/ping.js","leadinfo","LI-6241C0EB46037"));
	}

	
	addwordsConversion(type){
		window.gtag('config', 'AW-10905230719');
		if(type === 'brochure'){window.gtag('event', 'conversion', { 'send_to': 'AW-10905230719/vJZKCKfZ6L8DEP-6gtAo'})}
		if(type === 'contactform'){window.gtag('event', 'conversion', {'send_to': 'AW-10905230719/wHXgCOWUtb8DEP-6gtAo'})}
	}

	formatValue = (value) => {return value.toLocaleString("nl-NL")}
		
	render() {	
		return (
		<div className="app">			
			<Helmet>
				<meta charSet="utf-8" />
				<title>Partypay - De betaalapp voor festivals en evenementen</title>
				<meta name="description" content="Tickets, kluisjes en muntjes bij elkaar in één overzichtelijke app. Maak kennis met de toekomst van betalen op festivals en evenementen." />
				<meta name="keywords" cpntent="evenementen,festivals,payment,munten,tokens,tickets,kluisjes,scanners,betaal,betalen,pinonly,bandjes,djs" />
			</Helmet>			
			<div className="top">
				<div className="headerContainer">
					<div className="topBG"></div>
					<img src={logo} className="logo" alt="logo" />
					<div className="topLinkContainer mobileHide">
						<div href="" className="topLinks" onClick={() => this.waaromRef.current.scrollIntoView({behavior: 'smooth'})}>Waarom Partypay</div>
						<div href="" className="topLinks" onClick={() => this.recensieRef.current.scrollIntoView({behavior: 'smooth'})}>Recensies</div>
						<div href="" className="topLinks" onClick={() => this.overRef.current.scrollIntoView({behavior: 'smooth'})}>Over ons</div>
						<div href="" className="topLinks" onClick={() => this.contactRef.current.scrollIntoView({behavior: 'smooth'})}>Contact</div>
						<a onClick={() => this.addwordsConversion('brochure')} href="https://partypay.nl/Partypay_Informatie_Brochure.pdf" target="_blank" className="topLinks downloadLink">Download Brochure</a>
					</div>
					<div className="desktopHide">
						<Menu right width={250}>
							<div className="topLinkContainer">
								<div href="" className="mobileLinks" onClick={() => {document.querySelector('.bm-overlay').click(); this.waaromRef.current.scrollIntoView({behavior: 'smooth'})}}>Waarom Partypay</div>
								<div href="" className="mobileLinks" onClick={() => {document.querySelector('.bm-overlay').click(); this.recensieRef.current.scrollIntoView({behavior: 'smooth'})}}>Recensies</div>
								<div href="" className="mobileLinks" onClick={() => {document.querySelector('.bm-overlay').click(); this.overRef.current.scrollIntoView({behavior: 'smooth'})}}>Over ons</div>
								<div href="" className="mobileLinks" onClick={() => {document.querySelector('.bm-overlay').click(); this.contactRef.current.scrollIntoView({behavior: 'smooth'})}}>Contact</div>
								<a onClick={() => this.addwordsConversion('brochure')} href="https://partypay.nl/Partypay_Informatie_Brochure.pdf" target="_blank" className="mobileLinks" style={{display: 'block'}}>Download Brochure</a>
							</div>
						</Menu>
					</div>
				</div>
				<div className="topContainer">
					<div className="topLeft">
						<div>
							<h1>De betaal app voor evenementen</h1>
							<h2>Alles wat de bezoeker nodig heeft in één overzichtelijke app</h2>
						</div>
						<div className="topStores">
							<a href="https://apps.apple.com/nl/app/partypay/id1489855985?l=en" target="_blank" className="topAS"><img src={appstore} className="appstore" alt="appstore" /></a>
							<a href="https://play.google.com/store/apps/details?id=com.partypay" target="_blank" className="topGP"><img src={googleplay} className="googleplay" alt="googleplay" /></a>
						</div>
					</div>
					<div className="topRight">
						<img src={headerMockups} className="headerMockups" alt="appstore" />
					</div>
				</div>
			</div>			
			<div className="content">			
				<div className="sideBG1"></div>

				<div className="hoehetwerk">
					<div className="opeventsContainer">	
						<img src={ballBG} className="beanBG" />
						<img src={ballBG} className="ballBG" />
						<div className="hwcontainer">
							<div className="imgLeft">
								<img src={festivalbar} className="ooImg" alt="bestellingdelen" />	
							</div>
							<div className="hhwtextcontainer">
								<h1>Een razendsnel alternatief voor bestaande betaal oplossingen</h1>
								<p>Bestellingen worden in één beweging doorgegeven en betaald. Medewerkers hoeven alleen de drankjes uit te serveren die staan aangegeven op het scherm. Door het ontbreken van verbale communicatie en betalingshandelingen wordt bestellen <font style={{color: '#e7005c'}}>sneller</font> en <font style={{color: '#e7005c'}}>minder gevoelig voor fouten.</font></p>
							</div>
						</div>
					</div>				
				</div>

				<div className="vidContainer">
					<div className="sideBG2"></div>				
					<div className="vidContentContainer">	
						<div className="vidLeft">
							<h1>Een one-stop-oplossing waar bezoekers blij van worden</h1>
							<p>Met slechts één app hebben bezoekers toegang tot hun <font style={{color: '#e7005c'}}>tickets</font>, <font style={{color: '#e7005c'}}>lockers</font>, <font style={{color: '#e7005c'}}>muntjes</font> en alle essentiële evenement informatie. Zo hebben bezoekers een overzicht van het festival <font style={{color: '#e7005c'}}>assortiment</font>, hun <font style={{color: '#e7005c'}}>uitgaven</font>, de <font style={{color: '#e7005c'}}>timetable</font>, de <font style={{color: '#e7005c'}}>plattegrond</font>, facilitaire informatie en nog veel meer.</p>
						</div>
						<div className="visitorImg">
							<img src={visImg} className="visImg" alt="bestellingdelen" />
						</div>
					</div>
				</div>

				
				<div className="waarom" ref={this.waaromRef}>
					<div className="iconBG"></div>
					<h1 style={{zIndex: 1}}>Waarom Partypay</h1>
					<div className="waaromContainer">
						<div className="waaromColumn">
							<img src={waarom3} className="waaromImg" alt="waaromImg" />
							<div className="waaromText">
								<h4>Voordelig</h4>
								<p>Door het gebruik van zeer betaalbare betaalterminals en het rekenen van servicekosten aan bezoekers (gebruikelijk bij vergelijkbare apps), bieden we een voordelig alternatief.</p>
							</div>
						</div>
						<div className="waaromColumn">
							<img src={waarom2} className="waaromImg" alt="waaromImg" />
							<div className="waaromText">
								<h4>Offline</h4>
								<p>Internet storing? Geen paniek! De data wordt geüpdate zodra de verbinding weer terug is. Bezoekers kunnen altijd blijven bestellen. Bij een langdurige storing kunnen we achteraf incasseren.</p>
							</div>
						</div>
						<div className="waaromColumn">
							<img src={waarom1} className="waaromImg" alt="waaromImg" />
							<div className="waaromText">
								<h4>Eenvoudig</h4>
								<p>De kracht van Partypay ligt in zijn eenvoud, zowel bezoekers als medewerkers kunnen er direct mee overweg. Dit maakt het systeem zeer geschikt voor onervaren barpersoneel.</p>
							</div>
						</div>
					</div>
				</div>	
				<div className="stores">
					<div className="storesContainer">
						<h2>Hebben we jouw interesse gewekt?</h2>
						<h1>Download de app</h1>
						<div className="topStores">
							<a href="https://apps.apple.com/nl/app/partypay/id1489855985?l=en" target="_blank" className="topAS"><img src={appstore} className="appstore" alt="appstore" /></a>
							<a href="https://play.google.com/store/apps/details?id=com.partypay" target="_blank" className="topGP"><img src={googleplay} className="googleplay" alt="googleplay" /></a>
						</div>
					</div>
				</div>
				<div className="extras">
					<div className="extrasContainer">	
						<div className="extrasItemsContainer">	
							<div className="extra">
								<img src={extras1} className="extraImg" alt="extraImg" />
								<h1>Push notificaties</h1>
								<h2>Beter verbonden met je bezoekers dan ooit tevoren. Vanaf de aanschaf van een ticket tot de laatste dans.</h2>
							</div>
							<div className="extra">
								<img src={extras2} className="extraImg" alt="extraImg" />
								<h1>Accrediteer de crew</h1>
								<h2>Stuur je crew, artiesten of relaties een code voor het claimen van consumptie tegoed, guest tickets of lockers.</h2>
							</div>
							<div className="extra">
								<img src={extras3} className="extraImg" alt="extraImg" />
								<h1>Rondje halen? Check!</h1>
								<h2>Bezoekers kunnen eenvoudig en supersnel hun tegoed delen met vrienden. Zo betaald ieder voor zich.</h2>
							</div>
							<div className="extra">
								<img src={extras4} className="extraImg" alt="extraImg" />
								<h1>Batterij leeg?</h1>
								<h2>Partypay kan indien gewenst worden gekoppeld met een betaalpas, zo kan er altijd worden betaald!</h2>
							</div>
							<div className="extra">
								<img src={extras5} className="extraImg" alt="extraImg" />
								<h1>Geen 18? Geen druppel!</h1>
								<h2>Het is mogelijk om via IDIN de leeftijd van jouw bezoekers te verifiëren en alcohol verkoop te reguleren.</h2>
							</div>
							<div className="extra">
								<img src={extras6} className="extraImg" alt="extraImg" />
								<h1>Hardcups zonder gedoe</h1>
								<h2>Niet ingeleverde hardcups worden automatisch verekend, behalve bij de eerste bestelling.</h2>
							</div>
						</div>
					</div>
				</div>
				<div className="dashboard">
					<div className="dashboardContainer">	
						<div className="dashboardText">
							<h1>Alle data direct inzichtelijk</h1>
							<p>Organisatoren krijgen een real-time overzicht van alle data op hun evenement. Zo zien zij onder andere welke drankjes het goed doen bij welke bar. Hoe de huidige drukte is verdeeld over de barren, hoeveel een cateraar of foodtruck heeft omgezet, het aantal tokens in omloop, hoeveel tickets er zijn afgescanned en nog veel meer.</p>
							<div onClick={() => this.contactRef.current.scrollIntoView({behavior: 'smooth'})} className="dashboardBtn"><h2>Vraag een demo aan</h2></div>
						</div>
						<div className="dashboardImg mobileHide">
							<img src={dbImg} className="dbImg" alt="bestellingdelen" />
						</div>
					</div>				
				</div>
				<div className="recencies" ref={this.recensieRef}>
					<div className="recenciesContainer">	
						<h1 className="desktopHide">Wat zeggen event professionals over Partypay?</h1>	
						<div className="recenciesImages">					
							<div className="recencieImgContainer" style={{filter: this.state.currRecensie === 1 ? '' : 'grayscale(100%)', opacity: this.state.currRecensie === 1 ? 1 : 0.7}} onClick={() => {this.setState({currRecensie: 1}); clearInterval(this.recencieInterval)}}>
								<img src={joep} resizeMode="contain" className="recencieImg" alt="recencieImg" />
							</div>
							<div className="recencieImgContainer recencieImgContainer2" style={{filter: this.state.currRecensie === 2 ? '' : 'grayscale(100%)', opacity: this.state.currRecensie === 2 ? 1 : 0.7}} onClick={() => {this.setState({currRecensie: 2}); clearInterval(this.recencieInterval)}}>
								<img src={knot} resizeMode="contain" className="recencieImg recencieImg2" alt="recencieImg"/>
							</div>
							<div className="recencieImgContainer recencieImgContainer3" style={{filter: this.state.currRecensie === 3 ? '' : 'grayscale(100%)', opacity: this.state.currRecensie === 3 ? 1 : 0.7}} onClick={() => {this.setState({currRecensie: 3}); clearInterval(this.recencieInterval)}}>	
								<img src={pieter} resizeMode="contain" className="recencieImg recencieImg3" alt="recencieImg"/>
							</div>
							<div className="recencieImgContainer recencieImgContainer4" style={{filter: this.state.currRecensie === 4 ? '' : 'grayscale(100%)', opacity: this.state.currRecensie === 4 ? 1 : 0.7}} onClick={() => {this.setState({currRecensie: 4}); clearInterval(this.recencieInterval)}}>
								<img src={rob} resizeMode="contain" className="recencieImg recencieImg4" alt="recencieImg"/>
							</div>
						</div>
						<div className="recencieText">
							<img src={quoteBG} className="quoteBG mobileHide" />
							<h1 className="mobileHide">Wat zeggen event professionals?</h1>						
							<div className="recencie" style={{visibility: this.state.currRecensie === 1 ? 'visible' : 'hidden', opacity: this.state.currRecensie === 1 ? 1 : 0}}>						
								<div className="recencieTitle">
									<p style={{fontFamily: 'Kentledge-Heavy'}}>Joep de Bruin</p>
									<p style={{marginLeft: 7, marginRight: 7}} className="mobileHide">-</p>
									<p>Happy Feelings</p>									
								</div>
								<h3>Partypay is een zeer klantvriendelijk betaal systeem met faciliteiten die klaar zijn voor de toekomst. Er is nagedacht over de klantzijde maar ook zeker aan de organisatorzijde. Alle gebruikers blij!</h3>
							</div>
							<div className="recencie" style={{visibility: this.state.currRecensie === 2 ? 'visible' : 'hidden', opacity: this.state.currRecensie === 2 ? 1 : 0}}>						
								<div className="recencieTitle">
									<p style={{fontFamily: 'Kentledge-Heavy'}}>Jeroen knot</p>
									<p style={{marginLeft: 7, marginRight: 7}} className="mobileHide">-</p>
									<p>Entreemanagement DGTL / Extrema</p>									
								</div>
								<h3>Als alle bezoekers hun tickets sneller bij de hand hebben, bijvoorbeeld doormiddel van een ticket wallet zoals Partypay, scheelt dat enorm in de rijen bij de entree van het festival.</h3>
							</div>
							<div className="recencie" style={{visibility: this.state.currRecensie === 3 ? 'visible' : 'hidden', opacity: this.state.currRecensie === 3 ? 1 : 0}}>						
								<div className="recencieTitle">
									<p style={{fontFamily: 'Kentledge-Heavy'}}>Pieter Meijers</p>
									<p style={{marginLeft: 7, marginRight: 7}} className="mobileHide">-</p>
									<p>Grolsch Nederland</p>									
								</div>
								<h3>Partypay heeft als toegevoegde waarde dat je bepaalde producten extra onder de aandacht kan brengen, zowel voor toeleverancier als exploitant. Visueel en qua productplacering kan je zo gericht sturen op extra aankopen en dus extra omzet, danwel marge.</h3>
							</div>
							<div className="recencie" style={{visibility: this.state.currRecensie === 4 ? 'visible' : 'hidden', opacity: this.state.currRecensie === 4 ? 1 : 0}}>						
								<div className="recencieTitle">
									<p style={{fontFamily: 'Kentledge-Heavy'}}>Rob Voets</p>
									<p style={{marginLeft: 7, marginRight: 7}} className="mobileHide">-</p>
									<p>Eventsafe Nederland</p>									
								</div>
								<h3>Ik moet zeggen dat ik enorm onder de indruk was van het systeem. Het scheelt ook enorm in handlingskosten.</h3>
							</div>						
						</div>
					</div>				
				</div>					
				<div className="overons" ref={this.overRef}>
					<img src={beanBG2} className="beanBG2" />
					<img src={ballBG2} className="ballBG2" />
					<div className="overonsContainer">	
						<div className="overonsText">
							<h1>Met een gedreven team bouwen we aan de ultieme event app</h1>
							<p>Met een veelzijdig en gedreven team werken we aan het verbeteren van de customer journey rondom events. Bestaande technieken van Google en betaalproviders combineren we met onze unieke elementen, gebouwd door ervaren UX designers en doorgewinterde developers. Zo zijn we hard op weg de ultieme event tool te bouwen voor zowel organisatoren als bezoekers.</p>
						</div>
						<div className="overonsImg">
							<img src={kantoor} className="ooImg" alt="bestellingdelen" />
						</div>
					</div>				
				</div>			
				<div className="partnersNums">
					<div className="sideBG1" style={{backgroundColor: '#f1adc8', marginTop: 300, opacity: 0.5}}></div>	
					<div className="statnumsContainer">	
						<div className="statnum">
							<h1><AnimatedNumbers animateToNumber={133.343} configs={(number, index) => {return { mass: 1, tension: 400 * (index + 1), friction: 140 }}}></AnimatedNumbers></h1>
							<h2>downloads</h2>
						</div>
						<div className="statnum">
							<h1><AnimatedNumbers animateToNumber={998.453} configs={(number, index) => {return { mass: 1, tension: 400 * (index + 1), friction: 140 }}}></AnimatedNumbers></h1>
							<h2>drankjes besteld</h2>
						</div>
						<div className="statnum">
							<h1><AnimatedNumbers animateToNumber={258} configs={(number, index) => {return { mass: 1, tension: 400 * (index + 1), friction: 140 }}}></AnimatedNumbers></h1>
							<h2>Evenementen gedraaid</h2>
						</div>
					</div>			
				</div>		
				<div className="faq">
				<h1>Veelgestelde vragen</h1>
					<div className="faqContainer">	
						<div className="faqSideContainer">	
							<div className="faqItem" onClick={() => {this.setState({currFaqItem: this.state.currFaqItem === 3 ? 0 : 3})}}>
								<div style={{display: 'flex', alignItems: 'center'}}>
									<h2 className='faqArrow'>{this.state.currFaqItem === 3 ? '\u25BD' : '\u25B7'}{'\u00A0'}{'\u00A0'}</h2>
									<h2>Wat kost Partypay?</h2>
								</div>
								{this.state.currFaqItem === 3 ?
								<h3>Het gebruik van Partypay als platform is voor de afnemer volledig kosteloos. We rekenen, net zoals vele andere apps, een kleine service fee bij het opwaarderen. <br/><br/> Het is mogelijk om met eigen smartphones de bestellingen te scannen maar we adviseren bij grotere evenementen wel om onze vaste scan terminals te huren. Aangezien de apparaten alleen een bestelling hoeven te laten zien zijn de huurprijzen hiervoor erg aantrekelijk en niet te vergelijken met bijvoorbeeld een pin-only kassasysteem.</h3>
								:null}
							</div>
							<div className="faqItem" onClick={() => {this.setState({currFaqItem: this.state.currFaqItem === 2 ? 0 : 2})}}>
								<div style={{display: 'flex', alignItems: 'center'}}>
									<h2 className='faqArrow'>{this.state.currFaqItem === 2 ? '\u25BD' : '\u25B7'}{'\u00A0'}{'\u00A0'}</h2>
									<h2>Wat als een telefoon leeg is?</h2>
								</div>
								{this.state.currFaqItem === 2 ?
								<h3>Wij hebben een grote voorraad platte powerbanks die makelijk in een broek passen en worden geleverd met een mini kabeltje voor zowel Android als Iphone. De powerbank laadt de telefoon in korte tijd weer voor een groot gedeelte op. <br/><br/>Laat ons weten hoeveel (opgeladen) powerbanks je nodig hebt. Hiervoor wordt meestal een borg systeem gehanteerd. </h3>
								:null}
							</div>
							<div className="faqItem" onClick={() => {this.setState({currFaqItem: this.state.currFaqItem === 4 ? 0 : 4})}}>
								<div style={{display: 'flex', alignItems: 'center'}}>
									<h2 className='faqArrow'>{this.state.currFaqItem === 4 ? '\u25BD' : '\u25B7'}{'\u00A0'}{'\u00A0'}</h2>
									<h2>Hoe wordt ik uitbetaald?</h2>
								</div>
								{this.state.currFaqItem === 4 ?
								<h3>De omzet, welke vergaard wordt in aankopen van muntjes en lockers worden uitbetaald door onze partner PAY.nl. Tevens fungeren zij als derdengelden instelling zodat je verzekerd bent van je geld. </h3>
								:null}
							</div>
							<div className="faqItem" onClick={() => {this.setState({currFaqItem: this.state.currFaqItem === 5 ? 0 : 5})}}>
								<div style={{display: 'flex', alignItems: 'center'}}>
									<h2 className='faqArrow'>{this.state.currFaqItem === 5 ? '\u25BD' : '\u25B7'}{'\u00A0'}{'\u00A0'}</h2>
									<h2>Hoe werkt Partypay i.c.m. herbruikbare bekers?</h2>
								</div>
								{this.state.currFaqItem === 5 ?
								<h3>Bij de aanvang van het event zijn er verschillende opties, net wat prettig is. Of de eerste bestelling zijn de bekers gratis (de app weet wanneer het de eerste bestelling is) of de bezoeker krijgt een beker/fysieke beker munt bij de entree. Bezoekers bestellen zelf de producten, de bar medewerker krijgt bij elke bestelling de vraag hoeveel extra cups er moeten worden aangeslagen dit wordt dan automatisch verrekend.</h3>
								:null}
							</div>
						</div>
						<div className="faqSideContainer">
							<div className="faqItem" onClick={() => {this.setState({currFaqItem: this.state.currFaqItem === 1 ? 0 : 1})}}>
								<div style={{display: 'flex', alignItems: 'center'}}>
									<h2 className='faqArrow'>{this.state.currFaqItem === 1 ? '\u25BD' : '\u25B7'}{'\u00A0'}{'\u00A0'}</h2>
									<h2>Kun je nog steeds met Cash/Pin betalen?</h2>
								</div>
								{this.state.currFaqItem === 1 ?
								<h3>Het is mogelijk om nog steeds met een of meerdere vaste opwaardeerpunten te werken. De bezoeker krijgt dan de mogelijkheid om bij het opwaarderen in de app voor cash of pin te kiezen.</h3>
								:null}
							</div>
							<div className="faqItem" onClick={() => {this.setState({currFaqItem: this.state.currFaqItem === 8 ? 0 : 8})}}>
								<div style={{display: 'flex', alignItems: 'center'}}>
									<h2 className='faqArrow'>{this.state.currFaqItem === 8 ? '\u25BD' : '\u25B7'}{'\u00A0'}{'\u00A0'}</h2>
									<h2>Wat als het internet weg valt?</h2>
								</div>
								{this.state.currFaqItem === 8 ?
								<h3>De bezoekers zelf hebben alleen internet nodig om op te waarderen via ideal. Verder functioneert de app volledig offline. <br/><br/>Onze scan terminals kunnen offline functioneren. De bestellingen worden lokaal opgeslagen en gesynchroniseerd als het internet terug is. <br/><br/> Mocht het internet langdurig wegvallen dan resulteert dit mogelijk in een negatief saldo. Negatieve saldo's worden na het evenement automatisch geïncasseerd van de bezoeker zijn rekening. Hier wordt duidelijk over gecommuniceerd naar de bezoeker.</h3>
								:null}
							</div>
							<div className="faqItem" onClick={() => {this.setState({currFaqItem: this.state.currFaqItem === 7 ? 0 : 7})}}>
								<div style={{display: 'flex', alignItems: 'center'}}>
									<h2 className='faqArrow'>{this.state.currFaqItem === 7 ? '\u25BD' : '\u25B7'}{'\u00A0'}{'\u00A0'}</h2>
									<h2>Kunnen externe partijen hun eigen omzet inzien?</h2>
								</div>
								{this.state.currFaqItem === 7 ?
								<h3>Zeker! Je kan voor elke externe cateraar/foodtruck een account aanmaken waarmee zij kunnen inloggen op ons overzichtlijke 'cateraars dashboard'. Hier kunnen zijn een menu samenstellen, aanpassen, hun omzet en btw inzien en eventueel inkomende bestellingen beheren / push notificaties versturen wanneer een bestelling klaar is.</h3>
								:null}
							</div>
							<div className="faqItem" onClick={() => {this.setState({currFaqItem: this.state.currFaqItem === 6 ? 0 : 6})}}>
								<div style={{display: 'flex', alignItems: 'center'}}>
									<h2 className='faqArrow'>{this.state.currFaqItem === 6 ? '\u25BD' : '\u25B7'}{'\u00A0'}{'\u00A0'}</h2>
									<h2>Is er tijdens het event een medewerker beschikbaar?</h2>
								</div>
								{this.state.currFaqItem === 6 ?
								<h3>Er is 24/7 iemand beschikbaar om te helpen met het beantwoorden van eventuele vragen. Daarnaast spreken we vooraf duidelijk af wat het serviceniveau is voor het evenement, wat afhankelijk is van verschillende factoren. Bezoekers kunnen altijd tijdens een evenement via de whatsapp knop onder het account vragen stellen aan ons. De praktijk leert dat het gebruik voor zich spreekt.</h3>
								:null}
							</div>
						</div>
					</div>	
				</div>
				<div className="partners">
					<img src={ballBG} className="ballBG" style={{opacity: 0.5, marginTop: 370, marginLeft: 1100}} />
					<h1>Partners & Events</h1>
					<div className="partnersContent">
						<div className="partnersContainer">	
							<img src={eventix} className="partnerImg" alt="partnerImg" style={{width: 180}} />
							<img src={ytp} className="partnerImg" alt="partnerImg" style={{width: 230}} />
							<img src={paylogic} className="partnerImg" alt="partnerImg" style={{width: 180}} />
							<img src={tibbaa} className="partnerImg" alt="partnerImg" style={{width: 150, paddingLeft: 10, paddingRight: 10}} />
							<img src={gotickets} className="partnerImg" alt="partnerImg" style={{width: 120}} />
						</div>	
						<div className="partnersContainer">	
							<img src={lockercompany} className="partnerImg" alt="partnerImg" style={{width: 70}} />
							<img src={eventsafe} className="partnerImg" alt="partnerImg" style={{width: 160}} />
							<img src={keynius} className="partnerImg" alt="partnerImg" style={{width: 130}} />
							<img src={pay} className="partnerImg" alt="partnerImg" style={{width: 70}} />
							<img src={mollie} className="partnerImg" alt="partnerImg" style={{width: 110}} />
							<img src={festivalcadeau} className="partnerImg" alt="partnerImg" style={{width: 250, paddingLeft: 15, paddingRight: 15}} />
						</div>	
						<div className="partnersContainer">	
							<img src={happyfeelings} className="partnerImg" alt="partnerImg" style={{width: 240, paddingLeft: 30, paddingRight: 30}} />
							<img src={papichulo} className="partnerImg" alt="partnerImg" style={{width: 200, paddingLeft: 30, paddingRight: 30}}/>
							<img src={colorado} className="partnerImg" alt="partnerImg" style={{width: 140}} />
							<img src={breepark} className="partnerImg" alt="partnerImg" style={{width: 200, paddingLeft: 30, paddingRight: 30}} />
							<img src={asopos} className="partnerImg" alt="partnerImg" style={{width: 170, paddingLeft: 30, paddingRight: 20}} />
						</div>	
						<div className="partnersContainer">	
						</div>	
					</div>
				</div>		
				<div className="footer" ref={this.contactRef}>
					<div className="footerContainer">	
						<div className="footerTopContainer">	
							<div className="footerTopText">
								<h1>Vraag vrijblijvend een demonstratie aan</h1>
								<p>Hebben we je interesse gewekt? Uiteraard gaan we graag in gesprek over de mogelijkheden voor jouw evenement of locatie. Heb je een snelle vraag, of wil je een demonstratie inplannen? Laat een berichtje achter in het contactformulier of bel ons via het onderstaande telefoonnummer.</p>
								<div className="contactButtonContainer mobileHide">
									<a className="contactButtonMail" href="tel:+31854011114">
										<img src={phone} className="phone" alt="phone" />
										<div className="contactText">+31 854 011 114</div>
									</a>
									<a style={{marginLeft: 30, width: 215}} className="contactButtonMail" href="tel:+31854011114">
										<img src={mail} className="phone" alt="phone" />
										<div className="contactText">info@partypay.nl</div>
									</a>
								</div>
							</div>
							<div className="footerTopForm">
								<div className="footerForm">
									<h1>Neem contact op</h1>
									<input type="text" placeholder="E-mailadres"  value={this.state.mail} onChange={e => this.setState({emptyMail: false, mail: e.target.value})} style={{borderWidth: this.state.emptyMail ? 2 : 0, borderStyle: 'solid', borderColor: '#e7005c'}}/>
									<div className="footerTopFormRow">
										<input type="text" placeholder="Naam" value={this.state.name} onChange={e => this.setState({emptyName: false, name: e.target.value})} style={{width: 133.50, borderWidth: this.state.emptyName ? 2 : 0, borderStyle: 'solid', borderColor: '#e7005c'}} />	
										<input type="text" placeholder="Telefoonnummer" value={this.state.tel} onChange={e => this.setState({emptyTel: false, tel: e.target.value})} style={{marginLeft: 15, width: 133.50, borderWidth: this.state.emptyTel ? 2 : 0, borderStyle: 'solid', borderColor: '#e7005c'}} />	
									</div>
									<textarea placeholder="Typ een bericht" value={this.state.msg} onChange={e => this.setState({emptyMsg: false, msg: e.target.value})} style={{borderWidth: this.state.emptyMsg ? 2 : 0, borderStyle: 'solid', borderColor: '#e7005c'}}/>
									<div className="contactBtnContainer">
										{this.state.mailsend ?
										<h2 style={{fontSize: 17, marginTop: 25, color: '#e7005c'}}>Bericht is verzonden!</h2>
										:
										<div onClick={() => this.sendMail()} className="contactBtn"><h2>Verstuur bericht</h2></div>
										}
									</div>
								</div>
							</div>
						</div>	
						<div className="footerBottomContainer">											
							<div className="footerColumn">
								<img src={logo3} className="footerLogo" alt="logo" />
								<div className="footerNAW" style={{marginTop: 30}}>Partypay B.V.</div>
								<div className="footerNAW">KVK 83448012</div>								
								<div className="footerNAW">Veilingkade 15</div>
								<div className="footerNAW">4815 HC  Breda</div>
								<div className="footerNAW">+31 854 011 114</div>
								<div className="footerNAW">info@partypay.nl</div>
							</div>
							<div className="footerColumn">
								<h1>Navigeer</h1>
								<div href="" className="footerLinks" onClick={() => this.waaromRef.current.scrollIntoView({behavior: 'smooth'})}>Waarom Partypay</div>
								<div href="" className="footerLinks" onClick={() => this.recensieRef.current.scrollIntoView({behavior: 'smooth'})}>Recensies</div>
								<div href="" className="footerLinks" onClick={() => this.overRef.current.scrollIntoView({behavior: 'smooth'})}>Over ons</div>
								<div href="" className="footerLinks" onClick={() => this.contactRef.current.scrollIntoView({behavior: 'smooth'})}>Contact</div>
								<a href="https://dashboard.partypay.nl" className="footerLinks">Dashboard</a>
							</div>
							<div className="footerColumn">
								<h1>Download onze app</h1>
								<div className="appButtonContainer" style={{marginTop: 10}}>
									<a href="https://apps.apple.com/nl/app/partypay/id1489855985?l=en" target="_blank"><img src={appstore} className="appstore" alt="appstore" /></a>
									<a href="https://play.google.com/store/apps/details?id=com.partypay" target="_blank" style={{marginLeft: 15}}><img src={googleplay_white} className="googleplay" alt="googleplay" /></a>
								</div>
								<div className="socialMainContainer">
									<h1 style={{marginTop: 20}}>Volg ons</h1>
									<div className="socialContainer1">
										<a href="https://www.facebook.com/partypayapp"><img src={facebook} className="facebook" alt="facebook" /></a>
										<a href="https://www.instagram.com/partypay.app/"><img src={instagram} className="instagram" alt="instagram" /></a>
									</div>
								</div>
							</div>
						</div>	
					</div>
				</div>				
				<div className="bottom">
					<div className="bottomContainer">
						<p>2023 Partypay B.V. alle rechten voorbehouden</p>
						<div className="conditionContainer">
							<a href="https://partypay.nl/voorwaarden.pdf"><h4>Algemene voorwaarden, verwerkersovereenkomst, SLA</h4></a>
							<a href="https://partypay.nl/privacyverklaring.pdf"><h4>Privacy Policy</h4></a>
						</div>
					</div>	
				</div>				
			</div>		
		</div>	
	)}  
}

export default Ppsite;