import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

var config = {
	apiKey: "AIzaSyCWKCO99Pl0cUq4FBOGRYPxFjNNsXCZu5I",
	authDomain: "partypay-a3d12.firebaseapp.com",
	databaseURL: "https://partypay-a3d12.firebaseio.com",
	projectId: "partypay-a3d12",
	storageBucket: "partypay-a3d12.appspot.com",
	messagingSenderId: "286561888158",
	appId: "1:286561888158:web:21e057517e44b598"
};
if (!firebase.apps.length) {firebase.initializeApp(config)}
const functions = firebase.functions();

export {functions};

